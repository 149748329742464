import { RAILS_ENV, GOOGLE_ANALYTICS_ID } from './app_vars.js.erb'

window.GoogleAnalyticsObject = 'ga';
window.ga = window.ga || function() {
  (window.ga.q = window.ga.q || []).push(arguments)
};
window.ga.l = 1 * new Date();
ga('create', GOOGLE_ANALYTICS_ID, 'auto');
ga('require', 'linkid');
ga('send', 'pageview');

$(function() {
  if(RAILS_ENV != 'production') return;
  $.getScript('//www.google-analytics.com/analytics.js');
});
