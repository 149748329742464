import 'moment'
import './contrib/animate'
import 'firefly/jquery.firefly-0.7'

$(window).resize(function(e) {
	return; //TODO
	/*(if($(window).width() <= 1023) {
		$('#page-container').css({
			height: $('#content-container').height() + 200
		});
	} else {
		$('#page-container').css({
			height:'100%'
		});
	}*/
});

$(window).on('load', function(e) {
	$('#preloader').animateCss('fadeOut', function() {
		$(this).remove();
	});
	$('#title, #subtitle, #summary').animateCss('fadeInUp');
	$('#social-media-container a').animateCss('bounceIn');
});

$(function() {
	$.firefly({
		color: '#9c9c9c',	//-- firefly color
		minPixel: 1,
		maxPixel: 3,
		total : 50,
		on: '#bg-container'
	});

	if(!Modernizr.svg) {
		$('img.svg').each(function() {
			var $this = $(this);
			var alt_src = $this.data('alt-src');
			if (alt_src) $this.attr('src', alt_src).removeClass('svg');
		});
	}

	$(window).trigger('resize');

	window.refreshCountdown = function() {
		$.ajax({
			url: '/time',
			method: 'GET',
			cache: false,
			dataType: 'json'
		}).done(function(data) {
			var now = moment.unix(data.unix);
			var launch = moment.unix($('#countdown-container').data('launch-time'));
			var timeUntilLaunch = moment.duration(launch.subtract(now));
			var daysUntilLaunch = ('000' + Math.ceil(timeUntilLaunch.asDays())).slice(-3);
			$('#countdown-container div.digit').text(daysUntilLaunch);
			setTimeout(window.refreshCountdown, daysUntilLaunch.asMilliseconds() % (24 * 60 * 60 * 1000));
		});
	};
	if($('#countdown-container').length > 0) window.refreshCountdown();
});

