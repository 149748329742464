import 'animate.css'

(function($) {
  var method = function(animationName, onAnimationEndFn) {
    var $this = this;
    var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    $this.one(animationEndEvents, function(e) {
      $this.removeClass('animated ' + animationName);
      if($.isFunction(onAnimationEndFn)) $.proxy(onAnimationEndFn, this)(e);
    });
    $this.addClass('animated ' + animationName);
    return $this;
  }

  // Initiate methods
  $.fn.animateCss = function() {
    var args = arguments;
    var results = this.map(function(idx, el) {
      var $el = $(el);
      if(args.length >= 1 || typeof args[0] === 'object') {
        return method.apply($el, args);
      } else {
        $.error('You must provide at least one argument to the jQuery.animateCss library.');
      }
    });
    return results;
  };

})(jQuery);
