import 'airbrake-js'
import { AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY, RAILS_ENV } from './app_vars.js.erb'

$(function() {
  if (RAILS_ENV != 'production') return;
  window.Airbrake = new airbrakeJs.Client({
    projectId:  AIRBRAKE_PROJECT_ID,
    projectKey: AIRBRAKE_PROJECT_KEY
  });
  airbrakeJs.instrumentation.jquery(window.Airbrake, jQuery);
  $(window).on('error', function(e) {
    console.log('Error: ', e);
  });
});
