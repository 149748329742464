import { I18N_DEFAULT_LOCALE } from './app_vars.js.erb'
window.I18n = require('i18n-js');
I18n.missedTranslations = new Set();
I18n.defaultLocale = I18N_DEFAULT_LOCALE;
I18n.locale = $('html').attr('lang');
I18n.missingBehaviour = 'null';
I18n.defaultSeparator = '.';
I18n.fallbacks = false;

// hijak Missing Translation handler
I18n.hijackMissingTranslationHandler = function(func) {
  if(typeof func != 'function') return false;
  var realMTH = I18n.missingTranslation;
  I18n.missingTranslation = function() {
    var args = arguments;
    func.apply(I18n, args);
    return realMTH.apply(I18n, args);
  };
  return true;
}

I18n.hijackMissingTranslationHandler(function(key) {
  I18n.missedTranslations.add(key);
});

$.ajax({
  url: '/i18n/' + $('html').attr('lang'),
  cache: true,
  dataType: 'json',
  method: 'GET'
}).done(function(data, textStatus, jqXHR) {
  I18n.translations[$('html').attr('lang')] = data;
});
